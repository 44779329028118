import validate from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.27_a7hc6yaatcojtw4mqvupwzq5vy/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.27_a7hc6yaatcojtw4mqvupwzq5vy/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/apps/nuxt-front/middleware/auth.ts"),
  "is-admin": () => import("/app/apps/nuxt-front/middleware/is-admin.ts"),
  unauthenticated: () => import("/app/apps/nuxt-front/middleware/unauthenticated.ts")
}