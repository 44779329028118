import revive_payload_client_ZiLfp3WEcn from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.27_a7hc6yaatcojtw4mqvupwzq5vy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EtuyzE0LIF from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.27_a7hc6yaatcojtw4mqvupwzq5vy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xu2NgXqLDq from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.27_a7hc6yaatcojtw4mqvupwzq5vy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_7vit8M3Ob4 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.27_a7hc6yaatcojtw4mqvupwzq5vy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_k2uxXKjs63 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.27_a7hc6yaatcojtw4mqvupwzq5vy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_xz0nbsa6qW from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.27_a7hc6yaatcojtw4mqvupwzq5vy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zCtYJRjOeU from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.27_a7hc6yaatcojtw4mqvupwzq5vy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qUJv6dzic7 from "/app/apps/nuxt-front/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.2_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/nuxt-front/.nuxt/components.plugin.mjs";
import prefetch_client_X4RVhKKnhw from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.27_a7hc6yaatcojtw4mqvupwzq5vy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/app/apps/nuxt-front/.nuxt/primevue-plugin.mjs";
import plugin_client_wpPm95F4px from "/app/apps/nuxt-front/node_modules/.pnpm/@primevue+nuxt-module@4.2.2_@babel+parser@7.26.2_magicast@0.3.5_rollup@4.27.2_vue@3.5.13_typescript@5.6.3_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/app/apps/nuxt-front/.nuxt/unocss.mjs";
import maska_UHaKf2z1iQ from "/app/apps/nuxt-front/plugins/maska.ts";
import google_recaptcha_rYK9Kziv68 from "/app/apps/nuxt-front/plugins/google-recaptcha.ts";
export default [
  revive_payload_client_ZiLfp3WEcn,
  unhead_EtuyzE0LIF,
  router_xu2NgXqLDq,
  payload_client_7vit8M3Ob4,
  navigation_repaint_client_k2uxXKjs63,
  check_outdated_build_client_xz0nbsa6qW,
  chunk_reload_client_zCtYJRjOeU,
  plugin_vue3_qUJv6dzic7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_X4RVhKKnhw,
  primevue_plugin_egKpok8Auk,
  plugin_client_wpPm95F4px,
  unocss_MzCDxu9LMj,
  maska_UHaKf2z1iQ,
  google_recaptcha_rYK9Kziv68
]